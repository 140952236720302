import { toast } from 'react-toastify';
import Api from '.';

const businessProvider = {
  getAll: (params) => {
    return Api.get('/business', { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar clientes!');
        return error;
      });
  },
  async create(data) {
    return Api.post('/business', { ...data })
      .then((response) => {
        toast.success('Negócio criado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao criar negócio.');
      });
  },
  async delete(id) {
    return Api.delete(`/business/${id}`)
      .then((response) => {
        toast.success('Negócio deletado com sucesso.');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao deletar negócio.');
      });
  },
  async getById(id) {
    return Api.get(`/business/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar negócio');
      });
  },
  async getByCustomerId(customerId) {
    const business = await this.getAll({
      where: {
        key: 'customerId',
        value: customerId,
      },
      limit: 999,
    });

    return business.rows;
  },
  async alterBusinessDemand(demandId, businessId) {
    return Api.post(`/demands/${demandId}/alterBusiness`, { businessId })
      .then((response) => {
        toast.success('Negócio alterado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao alterar negócio!');
      });
  },
  associateBusiness(body) {
    return Api.post(`/business/associate-user`, body)
      .then((response) => {
        toast.success('Negócio associado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao associar negócio!');
      });
  },
  dessasociateBusiness(body) {
    return Api.post(`/business/desassociate-user`, body)
      .then((response) => {
        toast.success('Negócio desassociado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao desassociar negócio!');
      });
  },
  getAllActive: (params) => {
    return Api.get('/business/active', { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar clientes.');
        return error;
      });
  },
  sendMassiveDemandToBusiness: async (data) => {
    try {
      const { data: response } = await Api.post(
        '/business/sendMassiveDemand',
        data,
      );
      toast.success('Demanda enviada com sucesso!');
      return response;
    } catch (error) {
      toast.error('Erro ao enviar demanda!');
      return error;
    }
  },
  update: async (id, data) => {
    try {
      const { data: response } = await Api.patch(`/business/${id}`, data);
      toast.success('Cliente atualizado com sucesso!');
      return response;
    } catch (error) {
      toast.error('Erro ao atualizar cliente!');
      return error;
    }
  },
  getBusinessUsers: async (params) => {
    try {
      const { data: response } = await Api.get('/business/users', { params });
      return response;
    } catch (error) {
      toast.error('Erro ao buscar usuários!');
      return null;
    }
  },
  async getDemandsSlas({ businessId, demandTypeName }) {
    return Api.get(`/business/${businessId}/slas`, {
      params: { demandTypeName },
    })
      .then((response) => {
        return response.data;
      })
      .catch(() => {});
  },
};

export default businessProvider;

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CiLocationOn } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';
import FormGeneric from '../../../components/Form';

export default function FormAddressDataRH({ personTypes, form }) {
  const { register } = useForm();
  const employee = useSelector((state) => state.employees.selectedEmployee);
  const personType = useSelector((state) => state.employees);
  const [isInternacional, setIsInternacional] = useState(false);

  return (
    <FormGeneric useForm={form}>
      <Row>
        <InputCheckbox
          name="isInternacional"
          label="Estrangeiro"
          onChange={() => setIsInternacional(!isInternacional)}
        />
        <Col>
          {isInternacional ? (
            <InputGeneric
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder={`Digite o CEP `}
              icon={CiLocationOn}
              defaultValue={employee?.cep || employee?.ZIPCODE}
              id="foreignerZipCode"
            />
          ) : (
            <InputMask
              type="cep"
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder={
                personType.personType === 'CLT'
                  ? 'Digite o numero CEP do colabaorador'
                  : 'Digite o CEP do prestador de serviço'
              }
              reduxKey="employees"
              cepName="cep/ZIPCODE"
              icon={CiLocationOn}
              defaultValue={employee?.cep || employee?.ZIPCODE}
              register={register}
            />
          )}
        </Col>
        {personType?.personType === 'CLT' && (
          <Col>
            <InputGeneric
              name="nacionality"
              label="Nacionalidade"
              placeholder={
                personType.personType === 'CLT'
                  ? 'Digite a nacionalidade do colaborador'
                  : 'Digite a nacionalidade do prestador de serviço'
              }
              icon={CiLocationOn}
              defaultValue={employee?.nacionality}
              id="nacionality"
            />
          </Col>
        )}

        <Col>
          <InputGeneric
            name="address"
            label="Logradouro"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite o nome da rua do colaborador'
                : 'Digite o nome da rua do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.address}
            id="address"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressComplement"
            label="Complemento"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite o complemento do colaborador'
                : 'Digite o complemento do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.addressComplement}
            id="addressComplement"
          />
        </Col>
        <Col>
          <InputGeneric
            type="number"
            name="addressNumber"
            label="Número"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite o numero do endereço do colaborador'
                : 'Digite o numero do endereço do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.addressNumber}
            id="addressNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressDistrict"
            label="Bairro"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite o bairro do colaborador'
                : 'Digite o bairro do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.addressDistrict}
            id="addressDistrict"
          />
        </Col>
        <Col>
          <InputGeneric
            name="addressCity"
            label="Cidade"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite a cidade do colaborador'
                : 'Digite a cidade do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.addressCity}
            id="addressCity"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressState"
            label="Estado"
            placeholder={
              personType.personType === 'CLT'
                ? 'Digite o estado do colaborador'
                : 'Digite o estado do prestador de serviço'
            }
            icon={CiLocationOn}
            defaultValue={employee?.addressState}
            id="addressState"
          />
        </Col>
      </Row>
    </FormGeneric>
  );
}

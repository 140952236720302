import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdOpenInNew, MdPeople } from 'react-icons/md';
import setStoreRedux from '../../../utils/setStore';
import DateUtils from '../../../utils/DateUtils';

const columnsCustomers = (showActions) => [
  {
    name: 'Nome',
    minWidth: '15%',
    selector: (row) => row?.Customer?.name,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.Customer?.name}
      </p>
    ),
    sortable: true,
    orderArray: [{ model: 'Customer' }, 'name'],
  },
  {
    name: 'Email',
    selector: (row) => row?.Customer?.email,
    omit: true,
    sortable: false,
  },
  {
    name: 'Health Score',
    selector: (row) => row?.Customer?.healthScore || 'Indefinido',
    sortable: true,
    maxWidth: '10px',
    orderArray: [{ model: 'Customer' }, 'healthScore'],
  },
  {
    name: 'Representante Legal',
    minWidth: '15%',
    orderArray: [{ model: 'Customer' }, 'legalRepresentativeName'],
    selector: (row) => row?.Customer?.legalRepresentativeName,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.Customer?.legalRepresentativeName || 'Indefinido'}
      </p>
    ),
    sortable: true,
  },
  {
    name: 'Negócio',
    columnName: 'name',
    minWidth: '15%',
    selector: (row) => row?.name,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.name}
      </p>
    ),
    sortable: true,
  },
  {
    name: 'Criado em',
    orderArray: [{ model: 'Customer' }, 'createdAt'],
    maxWidth: '100px',
    selector: (row) => DateUtils.formatToLocale(row?.Customer?.createdAt),
    sortable: true,
  },
  {
    name: 'Tags',
    maxWidth: '100px',
    selector: (row) =>
      row?.Customer?.tags?.map((tag) => tag.name).join(',') || '',
    sortable: false,
    omit: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    maxWidth: '10px',
    cell: (row) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: '10px',
        }}
      >
        {showActions === true ? (
          <Link
            to={`/rh/${row.Customer.id}/${row.id}`}
            className="ms-1 justify-content-center"
          >
            <MdPeople className="hover hover-pointer" size={22} />
          </Link>
        ) : (
          <>
            <Link to={`/customers/${row?.Customer?.id}`}>
              <MdOpenInNew className="hover hover-pointer" size={22} />
            </Link>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: '10px',
              }}
            >
              <MdDelete
                className="hover-pointer"
                size={22}
                color="#D10000"
                onClick={() => {
                  setStoreRedux('SET_MODAL_STATE', {
                    isOpen: true,
                    id: row?.Customer?.id,
                    itemSelected: row,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    ),
  },
];

export default columnsCustomers;

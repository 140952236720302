import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { Col, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import FileInput from '../../../components/Inputs/Input_File';
import Table from '../../../components/Table/Table';
import { columnName, dataColumnTable, selects } from './data';
import s3 from '../../../providers/urlGenerator';
import businessProvider from '../../../providers/business';
import ReminderProvider from '../../../providers/reminder';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import schemaSendMassiveReminder from '../../../validators/schemas/reminder/create';
import { reminderTypesOptions } from '../../../constants/reminders';
import convertJSONToObjectGeneric from '../../../utils/ConvertJsonToObj';
import TagProvider from '../../../providers/tag';
import { CHURN_CUSTOMERS_TAGS } from '../../../constants/cs';

export default function SendMassiveReminders() {
  const businessesList = useSelector((state) => state.customerList);
  const [reminder, setReminder] = React.useState(null);
  const tags = useSelector((state) => state.tagsList.rows);

  const useFormOptions = {};
  useFormOptions.resolver = yupResolver(schemaSendMassiveReminder);
  const formHook = useForm(useFormOptions);

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const reminderId = queryParam.get('reminderId');

  async function fetchReminder(id) {
    const reminderResponse = await ReminderProvider.get(id);
    setReminder(reminderResponse);
    getFiltersSelectedDefault(reminderResponse);
  }

  useEffect(() => {
    if (reminderId) {
      fetchReminder(reminderId);
    } else {
      setReminder(undefined);
      getFiltersSelectedDefault(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminderId]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    if (data?.name.length > 100) {
      toast.error('O nome do lembrete não pode ter mais de 100 caracteres!');
      return;
    }

    if (dayjs(data.duoDate).isBefore(dayjs(), 'day')) {
      toast.error('Escolha uma data para vencimento maior ou igual que hoje!');
      return;
    }

    if (dayjs(data.duoDate).isBefore(dayjs(data.dateToNotify), 'day')) {
      toast.error(
        'Escolha uma data para notificação menor ou igual à data de vencimento!',
      );
      return;
    }

    const attachmentsLinks = await Promise.all(
      data?.attachmentsLinks?.map(async (file) => {
        const { awsFileKey } = await s3.uploadFile(file);
        return awsFileKey;
      }),
    );

    const request = {
      ...data,
      attachmentsLinks,
      where: businessesList.where,
    };

    if (reminderId) {
      await ReminderProvider.update(reminderId, request);
    } else {
      await ReminderProvider.create(request);
    }

    navigate(`/reminder`);
  };

  const fetch = async (page = null) => {
    const pageToFetch = page || businessesList.page;
    const customersResponse = await businessProvider.getAllActive({
      page: pageToFetch,
      limit: businessesList.limit,
      where: businessesList.where,
      order: businessesList.order,
      notIgnoreDemandSpecific: true,
    });

    const tagsFeteched = await TagProvider.getAll({
      limit: 1000,
      where: [
        {
          key: 'name',
          operator: 'notIn',
          value: CHURN_CUSTOMERS_TAGS,
        },
      ],
    });

    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        ...customersResponse,
      },
    });

    dispatch({
      type: 'SET_TAGS_LIST',
      payload: tagsFeteched,
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_CUSTOMER_STATE', payload: { order } });
    }
  };

  const handlePageChange = async (page) => {
    dispatch({ type: 'SET_CUSTOMER_STATE', payload: { page } });
  };

  const handlePerRowsChange = async (limit) => {
    dispatch({ type: 'SET_CUSTOMER_STATE', payload: { limit } });
  };

  let businessWhereToEffect = businessesList.where;
  if (Object.keys(businessWhereToEffect || {}).length === 0) {
    businessWhereToEffect = undefined;
  }

  useEffect(() => {
    if (reminderId && +reminderId !== reminder?.id) {
      return;
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reminder?.id,
    businessesList.page,
    businessesList.limit,
    businessWhereToEffect,
    businessesList.order,
  ]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_CUSTOMER_STATE' });
      dispatch({ type: 'RESET_FILTER_BUSINESS_STATE' });
      setReminder(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getFiltersSelectedDefault(reminderResponse) {
    const jsonFilter = convertJSONToObjectGeneric(reminderResponse?.jsonFilter);
    if (!jsonFilter || !reminderResponse) return;

    const list = Object.keys(jsonFilter);
    const where = [];
    list.forEach((item, index) => {
      if (item === '$Customer.tags$') {
        dispatch({
          type: 'SET_FILTER_BUSINESS_STATE',
          payload: {
            tagId: jsonFilter?.['$Customer.tags$']?.contains[0].id,
          },
        });
        where.push({
          key: '$Customer.tags$',
          operator: 'contains',
          value: jsonFilter?.['$Customer.tags$']?.contains[0].id,
          model: 'Tag',
          columnModel: 'id',
        });
      } else {
        dispatch({
          type: 'SET_FILTER_BUSINESS_STATE',
          payload: {
            [item]: Object.values(
              Object.values(jsonFilter)[index],
            )[0].replaceAll('%', ''),
          },
        });
        where.push({
          key: item,
          operator: 'iLike',
          value: Object.values(jsonFilter[item])[0]?.replaceAll('%', ''),
        });
      }
    });

    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        where,
      },
    });
  }

  if (reminderId && reminder === null) {
    return null;
  }

  return (
    <>
      <TitlePage title="Enviar Lembretes em Massa" item="Lembretes" />
      <MainPage>
        <FormGeneric useForm={formHook} onSubmit={handleSubmit}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome do lembrete"
            label="Nome*"
            icon={MdDriveFileRenameOutline}
            defaultValue={reminder?.name}
          />
          <InputSelect
            options={reminderTypesOptions}
            name="type"
            placeholder="Selecione o tipo do lembrete"
            label="Tipo*"
            defaultValue={reminder?.type}
          />
          <InputCalendar
            label="Data de Vencimento*"
            name="duoDate"
            defaultValue={
              reminder?.duoDate
                ? [dayjs(reminder?.duoDate)]
                : [dayjs().add(7, 'days')]
            }
          />
          <InputCalendar
            label="Data para notificar*"
            name="dateToNotify"
            defaultValue={
              reminder?.dateToNotify
                ? [dayjs(reminder?.dateToNotify)]
                : [dayjs()]
            }
          />
          <Form.Label className="mt-3">Anexos</Form.Label>
          <FileInput name="attachmentsLinks" />
          <InputGeneric
            name="link"
            placeholder="Digite um link para o lembrete"
            label="Link"
            defaultValue={reminder?.link}
          />

          <h5 className="border-top mt-4 mb-3 pt-3">Filtros:</h5>
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              className="text-white"
              variant="warning"
              type="button"
              onClick={() => {
                selects(businessesList?.businesses)?.forEach((select) => {
                  formHook.resetField(select.name);
                });
                dispatch({
                  type: 'SET_CUSTOMER_STATE',
                  payload: { where: {} },
                });
              }}
            >
              Limpar filtro
            </ButtonGeneric>
          </div>
          <>
            <Col
              style={{
                marginTop: '1rem',
                display: 'flex',
                gap: '1rem',
              }}
            >
              {selects(businessesList?.businesses)?.map((select, index) => {
                return (
                  index < 3 && (
                    <InputSelect
                      key={select.name}
                      options={select.options}
                      onChange={(e) => {
                        const payload = {
                          [select.name]: e,
                        };

                        let payloadToSend = [payload];

                        if (Array.isArray(businessesList.where)) {
                          businessesList.where.push(payload);
                          payloadToSend = businessesList.where;
                        }

                        dispatch({
                          type: 'SET_BUSINESS_LIST',
                          payload: { where: payloadToSend },
                        });
                        select.onChange(e);
                      }}
                      name={select.name}
                      label={select.label}
                      placeholder={select.placeholder}
                      className="ms-3"
                    />
                  )
                );
              })}
            </Col>
            <Col
              style={{
                display: 'flex',
                gap: '1rem',
              }}
            >
              {selects(businessesList?.businesses, tags)?.map(
                (select, index) => {
                  return (
                    index >= 3 && (
                      <InputSelect
                        key={select.name}
                        options={select.options}
                        onChange={(e) => {
                          dispatch({
                            type: 'SET_FILTER_BUSINESS_STATE',
                            payload: {
                              [select.name === 'tagId'
                                ? '$Customer.id$'
                                : select.name]: e,
                            },
                          });
                          select.onChange(e);
                        }}
                        name={select.name}
                        label={select.label}
                        placeholder={select.placeholder}
                        className="ms-3"
                        isMulti={select.name.includes('agId')}
                      />
                    )
                  );
                },
              )}
            </Col>
          </>

          {businessesList?.rows?.length > 0 ? (
            <Table
              className="mt-5"
              data={dataColumnTable(businessesList.rows)}
              columns={columnName}
              pagination
              paginationServer
              onSort={handleSort}
              onChangePage={handlePageChange}
              paginationTotalRows={businessesList.count}
              paginationDefaultPage={businessesList.page}
              onChangeRowsPerPage={handlePerRowsChange}
              itemsPerPage={businessesList.limit}
            />
          ) : (
            <h5 className="mt-5 text-center text-info">
              Nenhum Negócio encontrado
            </h5>
          )}

          <div className="pt-5 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
            >
              {reminderId ? 'Editar Lembrete' : 'Enviar Lembrete(s) em massa'}
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}

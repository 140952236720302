import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';

export default function InputMask({ ...props }) {
  // prop.stateKey

  const [maskedValue, setMaskedValue] = useState(props.defaultValue || '');
  const { form } = useSelector((state) => state[props?.reduxKey] || {});

  const getCep = async (cep) => {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const data = await response.json();

    data.logradouro && form.setValue('address', data?.logradouro);
    data.bairro && form.setValue('addressDistrict', data?.bairro);
    data.localidade && form.setValue('addressCity', data?.localidade);
    data.uf && form.setValue('addressState', data?.uf);

    return data;
  };

  useEffect(() => {
    const cep = form?.getValues(props?.cepName);
    if (cep?.length === 9) {
      getCep(cep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.getValues(props?.cepName)]);

  const styleIcon = {
    marginLeft: '-2.5rem',
    marginTop: '0.8rem',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    cursor: 'text',
  };

  const typeToMaxCharacters = {
    cpf: 14,
    cnpj: 18,
    phone: 16,
    cep: 9,
  };

  const maskInput = (value) => {
    switch (props?.type) {
      case 'cpf':
        return value.replace(/\D/g, '').replace(/^[0-9]{0,11}$/, (cpf) => {
          return cpf
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        });
      case 'cnpj':
        return value.replace(/\D/g, '').replace(/^[0-9]{0,14}$/, (cnpj) => {
          return cnpj
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
        });
      case 'phone':
        return value.replace(/\D/g, '').replace(/^[0-9]{0,11}$/, (phone) => {
          return phone
            .replace(/(\d{2})(\d)/, '($1) $2 ')
            .replace(/(\d{4})(\d)/, '$1-$2');
        });
      case 'cep':
        return value.replace(/\D/g, '').replace(/^[0-9]{0,8}$/, (cep) => {
          return cep.replace(/(\d{5})(\d{1,3})$/, '$1-$2');
        });
      case 'hour':
        return value
          .replace(/\D/g, '')
          .slice(0, 4)
          .replace(/(\d{2})(\d{1,2})$/, '$1:$2');
      case 'rg':
        return value.replace(/\D/g, '').replace(/^[0-9]{0,9}$/, (rg) => {
          return rg
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        });
      default:
        return value;
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maskedInputValue = maskInput(inputValue);
    setMaskedValue(maskedInputValue);
  };

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        {props?.label}
      </Form.Label>
      <div className="d-flex">
        <Form.Control
          className={props?.className}
          {...props?.register(props?.name, {
            required: props?.required,
            value: maskedValue,
          })}
          type="text"
          placeholder={props?.placeholder}
          value={maskedValue}
          onChange={handleInputChange}
          maxLength={typeToMaxCharacters[props?.type]}
          id={`inputMask${props?.type}`}
          style={{
            height: props?.as ? '100px' : '3rem',
            border: props?.error && '1px solid #DC3545',
            '::placeholder': {
              maxWidth: '60%',
            },
          }}
        />
        {props?.icon && !props.error && <props.icon {...propertyIcon} />}
        {props.error && (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        )}
      </div>
      {props.error && (
        <Form.Text className="text-danger">{props.error}</Form.Text>
      )}
    </Form.Group>
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BiRename } from 'react-icons/bi';
import { HiOutlineIdentification } from 'react-icons/hi';
import InputMask from '../../../components/Inputs/Input_Mask';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import FormGeneric from '../../../components/Form';

export default function FormPJ({ register, form }) {
  const employee = useSelector((state) => state.employees.selectedEmployee);
  const [documentType, setDocumentType] = React.useState(
    employee?.documentType || 'PF',
  );
  const documentOptions = [
    { value: 'PF', label: 'Profissional Autônomo (PF)' },
    { value: 'PJ', label: 'Pessoa Jurídica' },
  ];

  return (
    <FormGeneric useForm={form}>
      <Row className="m-0">
        <Col md={6}>
          <InputSelect
            name="documentType"
            label="Tipo de Documento"
            defaultValue={employee?.documentType}
            placeholder="Selecione o tipo de documento"
            onChange={(e) => setDocumentType(e)}
            options={documentOptions}
          />
        </Col>
        <Col md={6}>
          <InputGeneric
            name="name"
            label="Nome Completo do Prestador de Serviços*"
            defaultValue={employee?.name}
            placeholder={
              documentType === 'PF'
                ? 'Nome Completo do prestador de Serviço'
                : 'Nome Completo do Representante Legal'
            }
            icon={BiRename}
          />
        </Col>
        <Col md={12}>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF"
            placeholder={
              documentType === 'PF'
                ? 'CPF completo do prestador de Serviço'
                : 'CPF completo do Representante Legal'
            }
            icon={HiOutlineIdentification}
            defaultValue={employee?.cpf?.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4',
            )}
            register={register}
          />
        </Col>
        {documentType === 'PJ' && (
          <>
            <Col md={6}>
              <InputGeneric
                name="pjCorporateName"
                label="Razão Social*"
                defaultValue={employee?.pjCorporateName}
                placeholder="Razão Social"
                icon={BiRename}
              />
            </Col>
            <Col md={6}>
              <InputMask
                type="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="Digite o CNPJ do prestador de serviço"
                defaultValue={employee?.cnpj?.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5',
                )}
                register={register}
              />
            </Col>
          </>
        )}
      </Row>
    </FormGeneric>
  );
}

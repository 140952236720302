import React from 'react';
import DateUtils from '../../utils/DateUtils';
import { DeleteFolderButton, FolderLink, ShareButton } from './ActionsIndex';
import DispatchButton from '../../components/Buttons/DispatchButton';
import { foldersExcluded } from '../../constants/cs';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import EditIcon from '../../components/customIcons/EditIcon';

const columnsFolders = [
  {
    name: 'Nome da pasta',
    columnName: 'name',
    selector: (row) => FolderLink({ row }),
    sortable: true,
  },
  {
    name: 'Criado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    selector: (row) => (
      <div className="d-flex">
        <DispatchButton
          dispatchKey="SET_MODAL_STATE"
          payload={{
            modalDownloadFolder: {
              isOpen: true,
              folderId: row?.id,
            },
          }}
        >
          <DownloadIcon padded={false} />
        </DispatchButton>
        <DeleteFolderButton row={row} />
        {!foldersExcluded.has(row?.name) && (
          <DispatchButton
            id={`edit-folder-button-${row?.name}`}
            dispatchKey="SET_MODAL_STATE"
            variant="white"
            payload={{
              modalEditFolder: {
                isOpen: true,
                id: row?.id,
                folderId: row?.folderId,
                name: row?.name,
                userId: row?.userId,
                isTrash: row?.isTrash,
              },
            }}
          >
            <EditIcon padded={false} />
          </DispatchButton>
        )}
        <ShareButton row={row} />
      </div>
    ),
  },
];

export default columnsFolders;

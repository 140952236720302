import dayjs from 'dayjs';

const dateLocated = (date) => {
  return dayjs(date).locale('pt-br');
};

const DateUtils = {
  dateLocated,
  isValid: (date) => {
    return dayjs(date).isValid();
  },
  formatToLocale: (date, invalidAsBlank = true) => {
    if (dayjs(date).isValid() === false && invalidAsBlank) {
      return '';
    }
    const formatedDate = dateLocated(date).format('DD/MM/YYYY');
    return formatedDate;
  },
  formatToLocaleWithHour: (date, invalidAsBlank = true) => {
    if (dayjs(date).isValid() === false && invalidAsBlank) {
      return '';
    }
    const formatedDate = dateLocated(date).format('DD/MM/YYYY HH:mm');
    return formatedDate;
  },
  formatToLocaleWithText: (date, invalidAsBlank = true) => {
    if (dayjs(date).isValid() === false && invalidAsBlank) {
      return '';
    }
    const formatedDate = dateLocated(date).format('DD [de] MMMM [de] YYYY');
    return formatedDate;
  },
  formatToLocaleWithHourText: (date, invalidAsBlank = true) => {
    if (dayjs(date).isValid() === false && invalidAsBlank) {
      return '';
    }
    const formatedDate = dateLocated(date).format('HH:mm');
    return formatedDate;
  },
  formatTohHourText: (timeString, invalidAsBlank = true) => {
    if (
      typeof timeString !== 'string' ||
      !/^\d{2}:\d{2}:\d{2}$/.test(timeString)
    ) {
      return invalidAsBlank ? '' : timeString;
    }

    const [hours, minutes] = timeString.split(':');

    return `${hours}:${minutes}`;
  },

  createDateFromLocale: (date) => {
    const [day, month, year] = date.split('/');
    return dayjs(`${year}-${month}-${day}`);
  },
  getOnlyDay: (date) => {
    return dateLocated(date).format('DD');
  },
  timeToMinutesAndSeconds: (timeString) => {
    if (!/^\d{1,2}:\d{2}:\d{2}$/.test(timeString)) {
      return '';
    }

    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;

    return `${totalMinutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  },
};

export const holidays = new Array(12)
  .fill(false)
  .map((x) => new Array(31).fill(false));

export const holidaysDates = [
  '01/01',
  '02/13',
  '03/29',
  '04/21',
  '05/01',
  '06/08',
  '09/07',
  '10/12',
  '11/02',
  '11/15',
  '12/25',
];

export const holidaysDatesDayMonth = [
  '01/01',
  '13/02',
  '29/03',
  '21/04',
  '01/05',
  '08/06',
  '07/09',
  '12/10',
  '02/11',
  '15/11',
  '25/12',
];

holidaysDatesDayMonth.forEach((date) => {
  // populate matrix
  const [day, month] = date.split('/');

  holidays[+month - 1][+day - 1] = true;
});

export const isHoliday = (date) => {
  return holidays[date.month()][date.date() - 1];
};

export default DateUtils;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TooltipGeneric from '../../../components/Tooltip';
import EmployeeCard from '../../../components/EmployeesCard';
import ButtonGeneric from '../../../components/Button';
import employeesProvider from '../../../providers/employees';
import userProvider from '../../../providers/user';
import businessProvider from '../../../providers/business';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import PlusIcon from '../../../components/customIcons/PlusIcon';
import customerProvider from '../../../providers/customer';

const streaksConfig = [
  {
    title: 'Contratação Iniciada',
    status: 'Contratação Iniciada',
    tooltip: 'Este status indica que o processo de contratação foi iniciado.',
  },
  {
    title: 'Contratação Em Análise',
    status: 'Contratação Em Análise',
    tooltip: 'Este status indica que o processo está sendo analisado.',
  },
  {
    title: 'Contratação Finalizada',
    status: 'Contratação Finalizada',
    tooltip: 'Este status indica que o processo de contratação foi finalizado.',
  },
];

export default function HiringPanel({ setActiveKey }) {
  const activeTab = useSelector((state) => state.activeTabReducer.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const observerRefs = useRef({});
  const observer = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const {
    employeesToCustomer,
    employeesToBackoffice,
    employeesToCustomerFiltered,
    employeesToBackofficeFiltered,
    employeHiringName,
    selectedHiringBusiness,
    nextPages = {},
    hasMoreDatas = {},
    where,
    isFiltered,
  } = useSelector((state) => state.hiringsList);

  const fetchAndSetEmployees = async (status, page, filtered) => {
    const response = await employeesProvider.getHiring({
      page,
      limit: 1,
      where: [
        {
          key: 'hiringStatus',
          value: status,
          operator: 'iLike',
        },
        ...where,
      ],
      order: [['name', 'ASC']],
    });

    const newEmployees = response.rows;
    const isMoreDataAvailable = newEmployees.length >= 1;

    dispatch({
      type: 'SET_HIRINGS_LIST',
      payload: {
        status,
        hirings: newEmployees,
      },
    });

    dispatch({
      type: 'SET_PAGES',
      payload: {
        status,
        nextPages: page + 1,
      },
    });
    dispatch({
      type: 'SET_HAS_MORE_DATAS',
      payload: {
        status,
        hasMoreDatas: isMoreDataAvailable,
      },
    });
  };

  useEffect(() => {
    userProvider.userLogged().then((response) => {
      setUser(response.type);
    });

    streaksConfig.forEach(({ status }) => {
      fetchAndSetEmployees(status, 1, false);
    });
    dispatch({
      type: 'RESET_ACTIVE_TAB',
      payload: 'hiringPanel',
    });

    getSelectOptions();
    return () => {
      dispatch({ type: 'RESET' });
    };
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const status = entry.target.getAttribute('data-status');
          const hasMore =
            hasMoreDatas[status] !== undefined ? hasMoreDatas[status] : false;
          const nextPage =
            nextPages[status] !== undefined ? nextPages[status] : 1;

          if (hasMore) {
            fetchAndSetEmployees(status, nextPage, !!isFiltered);
          }
        }
      });
    };

    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '10px',
      threshold: 0.1,
    });

    Object.values(observerRefs.current).forEach((ref) => {
      if (ref) {
        observer.current.observe(ref);
      }
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMoreDatas, nextPages]);

  const combinedRef = (ref, status, index) => (element) => {
    if (ref) {
      ref(element);
    }

    const employeesList =
      user === 'CUSTOMER'
        ? isFiltered
          ? employeesToCustomerFiltered[status]
          : employeesToCustomer[status] || []
        : isFiltered
        ? employeesToBackofficeFiltered[status]
        : employeesToBackoffice[status] || [];

    const isLastElement = index === employeesList.length - 1;

    if (element && isLastElement) {
      observerRefs.current[status] = element;
    }
  };

  const updateEmployeeStatus = async (employeeId, newStatus) => {
    try {
      await employeesProvider.update(employeeId, {
        hiringStatus: newStatus,
        employeeId,
      });
      return true;
    } catch (error) {
      return true;
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (
      !destination ||
      !streaksConfig.some(({ status }) => status === destination.droppableId)
    ) {
      return;
    }

    const sourceStatus = source.droppableId;
    const destinationStatus = destination.droppableId;

    const sourceList =
      user === 'CUSTOMER'
        ? employeesToCustomer[sourceStatus] || []
        : employeesToBackoffice[sourceStatus] || [];

    const destinationList =
      user === 'CUSTOMER'
        ? employeesToCustomer[destinationStatus] || []
        : employeesToBackoffice[destinationStatus] || [];

    const [movedEmployee] = sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, movedEmployee);

    await updateEmployeeStatus(movedEmployee.id, destinationStatus);
    await fetchAndSetEmployees(sourceStatus, nextPages[sourceStatus]);
    await fetchAndSetEmployees(destinationStatus, nextPages[destinationStatus]);
  };

  const getSelectOptions = async () => {
    if (user === 'BACKOFFICE') {
      const customersResponse = await customerProvider.getAll({
        limit: 1000,
      });
      dispatch({
        type: 'SET_CUSTOMER_LIST',
        payload: { customerList: customersResponse.rows },
      });
    }
    const bussinessResponse = await businessProvider.getAll({});

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { businessList: bussinessResponse.rows },
    });
  };

  useEffect(() => {
    streaksConfig.forEach(async ({ status }) => {
      dispatch({ type: 'SET_PAGES', payload: { status, nextPages: 1 } });
      dispatch({
        type: 'SET_HAS_MORE_DATAS',
        payload: { status, hasMoreDatas: true },
      });

      await fetchAndSetEmployees(status, 1);
    });
  }, [where, employeHiringName, selectedHiringBusiness]);

  const initEditionHiring = async (employee, status) => {
    // eslint-disable-next-line no-param-reassign

    dispatch({
      type: 'SET_EMPLOYEES',
      payload: {
        personType: employee?.personType,
      },
    });
    navigate(`/rh/create/${employee.id}`);
  };

  const handleNavigateCLT = () => {
    setShowModal(false);
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: {
        selectedEmployee: {
          personType: 'CLT',
        },
      },
    });

    navigate(`/rh/create`);
  };

  const handleNavigatePJ = () => {
    setShowModal(false);
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: {
        selectedEmployee: {
          personType: 'PJ',
        },
      },
    });

    navigate(`/rh/create`);
  };
  useEffect(() => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: 'hiringPanel',
    });
  }, [activeTab]);

  return (
    <>
      <ModalGeneric show={showModal} close={handleCloseModal}>
        <Row className="justify-content-center gap-4 text-justify">
          <h4 className="text-center mb-3">Informe o tipo de Contratação</h4>
          <Col
            sm={5}
            className=" flex-column align-items-center card p-3 border rounded shadow"
          >
            <h5 className="text-center mb-3">Contratação CLT</h5>
            <ul style={{ height: '300px' }}>
              <li>
                <strong>Presença de subordinação:</strong> Existe controle de
                jornada, possibilidade de penalidades, entre outros.
              </li>
              <li className="mt-2">
                <strong>Direitos trabalhistas garantidos:</strong> Férias, 13º
                salário, FGTS, entre outros.
              </li>
              <li>
                <strong>Proteção legal:</strong> A legislação trabalhista
                garante estabilidade e segurança no emprego.
              </li>
              <li>
                <strong>Encargos trabalhistas:</strong> A empresa é responsável
                por encargos trabalhistas e previdenciários.
              </li>
            </ul>
            <ButtonGeneric
              id="btncreateCLT"
              onClick={handleNavigateCLT}
              className="mt-5 w-100"
            >
              Continuar para a Admissão CLT
            </ButtonGeneric>
          </Col>
          <Col
            sm={5}
            className=" flex-column align-items-center  card p-3 border rounded shadow"
          >
            <h5 className="text-center mb-3">Contratação PJ</h5>
            <ul className="" style={{ height: '300px' }}>
              <li>
                <strong>A ausência de subordinação:</strong> O trabalhador não
                está subordinado a um superior, atuando de forma independente.
              </li>
              <li>
                <strong>Contratação autônoma:</strong> O profissional atua como
                pessoa jurídica ou trabalhador autônomo.
              </li>
              <li>
                <strong>Regido pelo Código Civil:</strong> Permite maior
                flexibilidade para definir a forma de trabalho e negociar
                serviços.
              </li>
              <li>
                <strong>Sem encargos trabalhistas:</strong> A empresa não arca
                com encargos trabalhistas e previdenciários.
              </li>
            </ul>
            <ButtonGeneric
              id="btncreatePJ"
              onClick={handleNavigatePJ}
              className="mt-5 w-100"
            >
              Continuar para a Admissão PJ
            </ButtonGeneric>
          </Col>
        </Row>
      </ModalGeneric>
      <Row className="ms-3 align-items-center mt-5 mb-5">
        <Col className="justify-content-start align-items-center">
          <ButtonGeneric
            onClick={handleShowModal}
            className=" text-white d-flex align-items-center"
            type="submit"
            id="initHiringProcess"
          >
            <PlusIcon
              className="me-2"
              padded={false}
              fill="#fff"
              width={14}
              height={14}
            />{' '}
            Nova Admissão
          </ButtonGeneric>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-streaks" direction="horizontal">
          {(provided) => (
            <Row
              className="p-0 m-0"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {streaksConfig.map((streak) => (
                <Droppable
                  key={streak.status}
                  droppableId={streak.status}
                  direction="vertical"
                >
                  {(providedForStreak) => (
                    <Col
                      md={4}
                      ref={providedForStreak.innerRef}
                      {...providedForStreak.droppableProps}
                    >
                      <h5
                        style={{ borderBottom: '1px solid grey' }}
                        className="mb-5 mt-3"
                      >
                        {streak.title}
                        <TooltipGeneric title={streak.tooltip}>
                          <strong
                            style={{
                              cursor: 'pointer',
                              marginLeft: '10px',
                              borderRadius: '50%',
                              padding: '0px 4px',
                              backgroundColor: 'black',
                              color: 'white',
                              fontSize: '0.8rem',
                              alignItems: 'center',
                            }}
                          >
                            ?
                          </strong>
                        </TooltipGeneric>
                      </h5>
                      <div
                        style={{
                          height: '500px',
                          overflowY: 'scroll',
                        }}
                      >
                        <Droppable
                          droppableId={streak.status}
                          direction="vertical"
                        >
                          {(providedForInnerDroppable) => (
                            <div
                              ref={providedForInnerDroppable.innerRef}
                              {...providedForInnerDroppable.droppableProps}
                            >
                              <Row>
                                {(user === 'CUSTOMER'
                                  ? employeesToCustomer[streak.status] || []
                                  : employeesToBackoffice[streak.status] || []
                                ).map((employee, index) => (
                                  <Draggable
                                    key={employee.id}
                                    draggableId={employee.id}
                                    index={index}
                                  >
                                    {(providedForCard) => (
                                      <Col
                                        xs={12}
                                        className="mb-3"
                                        data-status={streak.status}
                                        ref={combinedRef(
                                          providedForCard.innerRef,
                                          streak.status,
                                          index,
                                        )}
                                        {...providedForCard.draggableProps}
                                        {...providedForCard.dragHandleProps}
                                        onClick={() =>
                                          initEditionHiring(
                                            employee,
                                            streak.status,
                                          )
                                        }
                                      >
                                        <EmployeeCard
                                          user={user}
                                          employee={employee}
                                        />
                                      </Col>
                                    )}
                                  </Draggable>
                                ))}
                                {providedForInnerDroppable.placeholder}
                              </Row>
                            </div>
                          )}
                        </Droppable>
                      </div>
                      {providedForStreak.placeholder}
                    </Col>
                  )}
                </Droppable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

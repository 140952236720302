import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DEMAND_CONSTANTS from '../constants/demands';
import DateUtils from '../utils/DateUtils';

const calculateDemandHeaderStatus = (demand) => {
  if (demand.statusKey === 'IN_PROGRESS') {
    if (dayjs(demand.deleveryForecast).isSame(dayjs(), 'day'))
      return 'EXPIRE_TODAY';

    if (dayjs(demand.deleveryForecast).isBefore(dayjs())) return 'DELAYED';

    if (dayjs(demand.deleveryForecast).isSame(dayjs().add(1, 'days'), 'days'))
      return 'EXPIRE_TOMORROW';

    if (!demand.checked) return 'UNCHECKED';

    return 'IN_PROGRESS';
  }

  return demand.statusKey;
};
export default function DemandCard({ demand }) {
  const navigate = useNavigate();
  const demandHeaderStatus = calculateDemandHeaderStatus(demand);
  const authUser = useSelector((state) => state.auth.auth);

  const headerDescription =
    DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[demandHeaderStatus].description;
  let textColor = DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[demandHeaderStatus].color;
  let backgroundColor = `${textColor}33`;

  if (
    demand.demandTypeName ===
    DEMAND_CONSTANTS.DEMAND_TYPES.CHURN_MEETING.description
  ) {
    textColor = '#fe59c2';
    backgroundColor = '#fe59c16e';
  }
  if (!demand.checked && demand.statusKey !== 'OPEN') {
    backgroundColor = DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.UNCHECKED.color + 33;
  }

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);

  return (
    <Card
      className="hover-pointer"
      onClick={() => {
        queryParam.set('demandId', demand.id);
        const newUrl = `${location.pathname}?${queryParam.toString()}`;
        navigate(newUrl, { replace: true });
      }}
    >
      <Card.Header
        className="pt-0 pb-0"
        style={{ color: textColor, backgroundColor, fontWeight: 'bolder' }}
      >
        <Row className="fs-14">
          <Col>
            {headerDescription}{' '}
            {demand.customer?.tags?.find(
              (tag) => tag.name === 'Inadimplente',
            ) && '🚩'}
          </Col>
          <Col className="text-end">
            {DateUtils.formatToLocale(demand.internDeliveryForecast)}
          </Col>
          {demand?.backofficeVisualized?.includes(authUser.id) ? '' : '📩'}
        </Row>
      </Card.Header>
      <Card.Body className="p-2">
        <Card.Title
          className="fs-16 text-truncate"
          id={`demandCard${demand.externalId}`}
        >
          {demand.externalId} - {demand.name}
        </Card.Title>
        <Card.Subtitle className="fs-14">{demand.customer?.name}</Card.Subtitle>
        <Card.Text className="mb-1 fs-14">{demand.demandTypeName}</Card.Text>

        <hr className="m-0" />

        <Card.Text className="fs-12">
          Data de entrega:{' '}
          {DateUtils.formatToLocaleWithHour(demand.deleveryForecast)}
          <br />
          Criada em: {DateUtils.formatToLocaleWithHour(demand.createdAt)}
          <br />
          Última atualização:{' '}
          {DateUtils.formatToLocaleWithHour(demand.lastBackofficeAction)}
          <br />
          Negócio: {demand.Business?.name}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from './Table/Table';
import columnsCustomers from '../pages/Customer/list/customerColumns';
import businessProvider from '../providers/business';
import customerProvider from '../providers/customer';
import InputFilter from './Inputs/Input_Filter';
import ModalConfirmation from './Modals/ModalConfirmation';
import optionsInputFilterToCustomerList from '../pages/Customer/list/optionsData';

export default function CustomerList({ showActions }) {
  const customerList = useSelector((state) => state.customerList);
  const tagsList = useSelector((state) => state.tagsList);
  const modalData = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setCustomers = ({ rows, count }) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { rows, count },
    });
  };

  const fetch = async (page = null) => {
    const pageToFetch = page || customerList.page;
    const customers = await businessProvider.getAll({
      page: pageToFetch,
      limit: customerList.limit,
      where: customerList.where,
      order: customerList.order,
      includeWithoutDetails: true,
    });
    return setCustomers(customers);
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName || column.orderArray) {
      dispatch({ type: 'SET_CUSTOMER_STATE', payload: { order } });
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { page },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { limit },
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'SET_MODAL_STATE',
      payload: { isOpen: false },
    });
  };

  const deleteCustomer = async (id) => {
    await customerProvider.delete(id);
    closeModal();
    await fetch();
  };

  const onSubmit = (data) => {
    const optionWhere = optionsInputFilterToCustomerList({
      tagsList: tagsList.rows,
    }).find((option) => option.value === data.type);

    const { model, columnModel, operator } = optionWhere;

    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        where: [
          {
            key: data.type,
            operator,
            value: data.filter,
            model,
            columnModel,
          },
        ],
      },
    });
  };

  const resetFilterSearch = () => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { where: {} },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerList.page,
    customerList.limit,
    customerList.where,
    customerList.order,
  ]);

  return (
    <div>
      <InputFilter
        onSubmit={onSubmit}
        options={optionsInputFilterToCustomerList({
          tagsList: tagsList.rows,
        })}
        reset={resetFilterSearch}
        filterParams={{ name: 'filter' }}
      />
      {customerList?.rows?.length > 0 ? (
        <Table
          className="mt-5"
          data={customerList.rows}
          columns={columnsCustomers(showActions)}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={customerList.count}
          paginationDefaultPage={customerList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={customerList.limit}
          sortServer
          fetchAction={fetch}
          onRowClicked={(row) => navigate(`/customers/${row?.Customer?.id}`)}
        />
      ) : (
        <p className="text-center text-info mt-5 border border-2 rounded">
          Sem nenhum item encontrado.
        </p>
      )}
      <ModalConfirmation
        close={() => closeModal()}
        open={modalData.isOpen}
        onConfirmation={() => deleteCustomer(modalData.id)}
        title="Deletar Cliente"
        description="Deseja realmente deletar o cliente? Essa ação não poderá ser desfeita."
      />
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BiReset, BiSearchAlt } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputGeneric from './Inputs/Input_generic';
import InputSelect from './Inputs/Input_Select';
import ButtonGeneric from './Button';
import businessProvider from '../providers/business';
import customerProvider from '../providers/customer';
import userProvider from '../providers/user';

function FilterComponent({ bussinessList }) {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const customerlist = useSelector(
    ({ customerList }) => customerList.customerList,
  );
  const { employeeName, selectedBusiness } = useSelector(
    (state) => state.employeesList,
  );
  const { employeHiringName, selectedHiringBusiness } = useSelector(
    (state) => state.hiringsList,
  );
  const { employeResignationName, selectedResignationBusiness } = useSelector(
    (state) => state.resignationList,
  );
  const activeTab = useSelector((state) => state.activeTabReducer.activeTab);
  const { customerId, businessId } = useParams();
  const [user, setUser] = useState('');

  const onSelectName = (name) => {
    if (activeTab === 'employee') {
      dispatch({ type: 'RESET_EMPLOYEES_STATE' });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          employeeName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          where: [
            {
              key: 'name',
              value: name,
              operator: 'iLike',
            },
            selectedBusiness
              ? { key: 'businessId', value: selectedBusiness, operator: 'eq' }
              : null,
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'hiringPanel') {
      dispatch({ type: 'RESET_HIRINGS_LIST' });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          employeHiringName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          where: [
            {
              key: 'name',
              value: name,
              operator: 'iLike',
            },
            selectedHiringBusiness
              ? {
                  key: 'businessId',
                  value: selectedHiringBusiness,
                  operator: 'eq',
                }
              : null,
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'resignation') {
      dispatch({ type: 'RESET_RESIGNATION_LIST' });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          employeResignationName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          where: [
            {
              key: '$employee.name$',
              value: name,
              operator: 'iLike',
            },
            selectedResignationBusiness
              ? {
                  key: 'businessId',
                  value: selectedResignationBusiness,
                  operator: 'eq',
                }
              : null,
          ],
          order: [['$employee.name$', 'ASC']],
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSelectName(e.target.value, selectedBusiness);
    }
  };

  const onSelectBusiness = (id) => {
    if (activeTab === 'employee') {
      dispatch({
        type: 'RESET_EMPLOYEES_STATE',
      });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          selectedBusiness: id,
        },
      });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          where: [
            {
              key: 'businessId',
              value: id,
              operator: 'eq',
            },
            {
              key: '$Employees.name$',
              value: employeeName,
              operator: 'iLike',
            },
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'hiringPanel') {
      dispatch({ type: 'RESET_HIRINGS_LIST' });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          selectedHiringBusiness: id,
        },
      });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          where: [
            {
              key: 'businessId',
              value: id,
              operator: 'eq',
            },
            {
              key: '$Employees.name$',
              value: employeHiringName,
              operator: 'iLike',
            },
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'resignation') {
      dispatch({ type: 'RESET_RESIGNATION_LIST' });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          selectedResignationBusiness: id,
        },
      });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          where: [
            {
              key: '$Resignations.businessId$',
              value: id,
              operator: 'eq',
            },
            {
              key: '$employee.name$',
              value: employeResignationName,
              operator: 'iLike',
            },
          ],
          order: [['$employee.name$', 'ASC']],
        },
      });
    }
  };

  const onSelectCustomer = async (id) => {
    dispatch({
      type: 'RESET_BUSINESS_LIST',
    });
    const bussinessResponse = await businessProvider.getAll({
      where: [
        {
          key: 'customerId',
          value: id,
          operator: 'eq',
        },
      ],
    });
    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { businessList: bussinessResponse.rows },
    });
  };

  const resetFilterSearch = async () => {
    const input = document.getElementsByName('employeeName');
    if (input[0]) {
      input[0].value = '';
    }
    if (user === 'BACKOFFICE') {
      dispatch({
        type: 'RESET_FILTER',
      });
      dispatch({
        type: 'RESET_FILTER_HIRING',
      });
      dispatch({
        type: 'RESET_BUSINESS_LIST',
      });
      dispatch({
        type: 'RESET_CUSTOMER_STATE',
      });
      const bussinessResponse = await businessProvider.getAll({
        limit: 1000,
      });
      const customersResponse = await customerProvider.getAll({
        limit: 1000,
      });
      dispatch({
        type: 'SET_CUSTOMER_LIST',
        payload: { customerList: customersResponse.rows },
      });
      dispatch({
        type: 'SET_BUSINESS_LIST',
        payload: { businessList: bussinessResponse.rows },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await userProvider.userLogged();
      setUser(response.type);

      if (response.type === 'BACKOFFICE') {
        const customersResponse = await customerProvider.getAll({
          limit: 1000,
        });
        dispatch({
          type: 'SET_CUSTOMER_LIST',
          payload: { customerList: customersResponse.rows },
        });
        const bussinessResponse = await businessProvider.getAll({
          limit: 1000,
        });

        dispatch({
          type: 'SET_BUSINESS_LIST',
          payload: { businessList: bussinessResponse.rows },
        });
      }
    };

    fetchData();
  }, []);

  return (
    <Row className="align-items-center justify-content-end">
      {user === 'BACKOFFICE' && (
        <>
          <Col sm={3} className="">
            <InputSelect
              options={customerlist?.customerList?.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              defaultValue={customerId}
              placeholder="Selecione um Ciente"
              name="businessId"
              label="Cliente*"
              onChange={(e) => onSelectCustomer(e)}
            />
          </Col>
          <Col sm={3} className="">
            <InputSelect
              options={bussinessList?.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              defaultValue={businessId}
              placeholder="Selecione um Negócio"
              name="businessId"
              label="Negócio*"
              onChange={(e) => onSelectBusiness(e)}
            />
          </Col>
        </>
      )}
      <Col sm={3}>
        <InputGeneric
          className="mt-2"
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Pesquisar por nome"
          name="employeeName"
        />
      </Col>
      <Col sm={1} md={1} className="mt-4 align-items-center text-center">
        <ButtonGeneric
          onClick={() => onSelectName(inputValue)}
          className="mt-2 text-white  m-0"
        >
          <BiSearchAlt size={25} />
        </ButtonGeneric>
      </Col>
      {user === 'BACKOFFICE' && (
        <Col sm={1} className="mt-4 align-items-center ">
          <BiReset
            onClick={resetFilterSearch}
            className="mt-2 hover-pointer hover"
            size={22}
          />
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;

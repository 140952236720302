import * as yup from 'yup';

export function isCPFValid(cpf) {
  // eslint-disable-next-line no-param-reassign
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  const firstDigit = remainder >= 10 ? 0 : remainder;

  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  const secondDigit = remainder >= 10 ? 0 : remainder;

  if (
    parseInt(cpf.charAt(9), 10) !== firstDigit ||
    parseInt(cpf.charAt(10), 10) !== secondDigit
  ) {
    return false;
  }

  return true;
}
const schemaCreateCLT = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  ctps: yup.string().required('Campo obrigatório'),
  rg: yup.string().required('Campo obrigatório'),
  cargo: yup.string().required('Campo obrigatório'),
  salario: yup.string().required('Campo obrigatório'),
  workload: yup
    .number()
    .typeError('Deve ser um número')
    .positive('A carga horária deve ser um valor positivo')
    .required('Campo obrigatório'),
  entryTime: yup
    .string()
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário de entrada inválido')
    .required('Campo obrigatório'),
  exitTime: yup
    .string()
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário de saída inválido')
    .required('Campo obrigatório'),
  breakTime: yup.string().required('Campo obrigatório'),
  amountWorkDays: yup
    .number()
    .typeError('Deve ser um número')
    .positive('A quantidade de dias de trabalho deve ser um valor positivo')
    .required('Campo obrigatório'),
  isInternacional: yup.boolean(),
  'cep/ZIPCODE': yup
    .string()
    .required('Campo obrigatório')
    .when('isInternacional', {
      is: false,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .matches(/^\d{5}-\d{3}$|^\d{5}$/, 'CEP/ZIPCODE inválido'),
      otherwise: () => yup.string().required('Campo obrigatório'),
    }),
  address: yup.string().required('Campo obrigatório'),
  addressNumber: yup.number().required('Campo obrigatório'),
  addressDistrict: yup.string().required('Campo obrigatório'),
  addressCity: yup.string().required('Campo obrigatório'),
  addressState: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  experiencePeriod: yup.boolean().required('Campo obrigatório'),
  journeyWorkType: yup.string().required('Campo obrigatório'),
  departament: yup.string().required('Campo obrigatório'),
  nacionality: yup.string().required('Campo obrigatório'),
  customerId: yup.string().required('Campo obrigatório'),
  businessId: yup.string().required('Campo obrigatório'),
});
const schemaCreatePJ = yup.object().shape({
  name: yup.string().required('Nome do Representante Legal é obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test('is-valid-cpf', 'CPF inválido', (value) => isCPFValid(value)),
  pjCorporateName: yup.string().notRequired('Razão Social é obrigatória'),
  cnpj: yup.string().notRequired('CNPJ é obrigatório'),
  cargo: yup.string().required('Cargo / Função é obrigatório'),
  payday: yup.date().required('Data do Pagamento é obrigatória'),
  firstPayday: yup.date().required('Data do Pagamento é obrigatória'),
  methodpay: yup.string().required('Método de pagamento é obrigatório'),
  isInternacional: yup.boolean(),
  'cep/ZIPCODE': yup
    .string()
    .required('Campo obrigatório')
    .when('isInternacional', {
      is: false,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .matches(/^\d{5}-\d{3}$|^\d{5}$/, 'CEP/ZIPCODE inválido'),
      otherwise: () => yup.string().required('Campo obrigatório'),
    }),
  address: yup.string().required('Campo obrigatório'),
  addressNumber: yup.number().required('Campo obrigatório'),
  addressDistrict: yup.string().required('Campo obrigatório'),
  addressCity: yup.string().required('Campo obrigatório'),
  addressState: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  documentType: yup.string().required('Tipo de Documento é obrigatório'),
  salario: yup.string().required('Campo obrigatório'),
  isFine: yup.string().notRequired('Campo obrigatório'),
  validityPeriod: yup.number().required('Campo obrigatório'),
  customerId: yup.string().required('Campo obrigatório'),
  businessId: yup.string().required('Campo obrigatório'),
});

export { schemaCreateCLT, schemaCreatePJ };
